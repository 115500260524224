import {cva} from 'class-variance-authority';

import Typography from '@/components/base/elements/Typography/Typography';
import type {Mode} from '@/components/base/types';
import {useTranslations} from '@/components/shared/Page/I18N';
import useReducedMotion from '@/hooks/useReducedMotion';
import {twMerge} from '@/stylesheets/twMerge';
import {useRetailIncentive} from '@/hooks/experiments/useRetailIncentive';
import useSignup from '@/hooks/useSignup';
import {useFlag} from '@/hooks/useFlag';
import {useSiteData} from '@/hooks/useSiteData';
import {FLAG_RSI_RETAIL_PLAN_INCENTIVE} from '@/flags';

export type IncentiveDetails = {
  rate?: number | null;
  gpvLimit?: string | null;
  length?: number | null;
  price?: string | null;
};

interface BannerWrapperProps {
  mode?: Mode;
  className?: string;
  children?: React.ReactNode;
}

interface BannerContentProps {
  incentiveDetails?: IncentiveDetails | null;
}

interface PosIncentivesPromoBannerProps {
  mode?: Mode;
  className?: string;
  termsLink?: string;
  incentiveDetails?: IncentiveDetails | null;
  showCardReaderDiscountIncentive?: boolean;
}

const BannerStyles = cva(
  [
    'relative z-10 animate-incentives-fill-background px-2 py-1 font-normal text-body-base rounded align-top leading-[2rem] box-decoration-clone',
  ],
  {
    variants: {
      mode: {
        dark: 'text-black bg-gradient-to-r bg-right-top from-[#075E3426_50%] to-[transparent_50%] mix-blend-hard-light bg-[length:200%_100%]',
        darkReducedMotion: 'text-black bg-[#075E3426]',
        light:
          'text-white bg-gradient-to-r bg-right-top from-[#CFA1FC33_50%] to-[transparent_50%] mix-blend-hard-light bg-[length:200%_100%]',
        lightReducedMotion: 'text-white bg-gradient-to-r bg-[#CFA1FC33]',
      },
    },
  },
);

const BannerWrapper = ({mode, children, className}: BannerWrapperProps) => {
  const prefersReducedMotion = useReducedMotion();

  const bannerMode = prefersReducedMotion
    ? ((mode + 'ReducedMotion') as Mode)
    : mode;

  return (
    <div
      className={twMerge('items-center relative overflow-hidden', className)}
    >
      <Typography
        size="body-base"
        className={BannerStyles({mode: bannerMode})}
        as="span"
      >
        {children}
      </Typography>
    </div>
  );
};

const SubscriptionIncentive = ({incentiveDetails}: BannerContentProps) => {
  const {t} = useTranslations();
  const {signupUrl} = useSignup();

  return (
    <a
      href={signupUrl}
      data-component-name="pos-incentives-promo-banner"
      style={{textDecoration: 'none', fontWeight: 'normal'}}
    >
      {t('global:incentivesPromoBanner.retail.subscriptionIncentive', {
        incentivePrice: incentiveDetails?.price,
      })}
    </a>
  );
};

const SPRateIncentive = ({incentiveDetails}: BannerContentProps) => {
  const {t} = useTranslations();
  const {signupUrl} = useSignup();
  const {termsLink} = useRetailIncentive();

  return (
    <>
      <a
        href={signupUrl}
        style={{textDecoration: 'none', fontWeight: 'normal'}}
      >
        {t('global:incentivesPromoBanner.retail.spRateIncentive', {
          rate: incentiveDetails?.rate,
          gpvLimit: incentiveDetails?.gpvLimit,
          length: incentiveDetails?.length,
        })}
      </a>{' '}
      <a
        id="spIncentivesTermsLink"
        target="_blank"
        className="!font-normal cursor-pointer"
        data-component-name="sp-incentive-terms"
        href={termsLink}
        rel="noreferrer"
      >
        {t('global:incentivesPromoBanner.retail.spRateIncentiveTerms')}
      </a>
    </>
  );
};

const CardReaderDiscountIncentive = () => {
  const {t} = useTranslations();
  const {signupUrl} = useSignup();
  const {getUrl} = useSiteData();

  return (
    <>
      <a
        href={signupUrl}
        style={{textDecoration: 'none', fontWeight: 'normal'}}
      >
        {t('global:incentivesPromoBanner.retail.cardReaderDiscountIncentive')}
      </a>{' '}
      <a
        id="cardReaderDiscountIncentiveTermsLink"
        target="_blank"
        className="!font-normal cursor-pointer"
        data-component-name="card-reader-discount-incentive-terms"
        href={getUrl('/card-reader-promotion/terms-and-conditions')}
        rel="noreferrer"
      >
        {t(
          'global:incentivesPromoBanner.retail.cardReaderDiscountIncentiveTerms',
        )}
      </a>
    </>
  );
};

export default function PosIncentivesPromoBanner({
  mode,
  className,
  incentiveDetails,
  showCardReaderDiscountIncentive,
}: PosIncentivesPromoBannerProps) {
  const {isSPRateIncentive, isControl} = useRetailIncentive();
  const enablePosIncentive = useFlag(FLAG_RSI_RETAIL_PLAN_INCENTIVE);

  if (!incentiveDetails && !showCardReaderDiscountIncentive) {
    return null;
  }

  return (
    (isSPRateIncentive && (
      <BannerWrapper mode={mode} className={className}>
        <SPRateIncentive incentiveDetails={incentiveDetails} />
      </BannerWrapper>
    )) ||
    (enablePosIncentive && (
      <BannerWrapper mode={mode} className={className}>
        <SubscriptionIncentive incentiveDetails={incentiveDetails} />
      </BannerWrapper>
    )) ||
    (showCardReaderDiscountIncentive && (
      <BannerWrapper mode={mode} className={className}>
        <CardReaderDiscountIncentive />
      </BannerWrapper>
    )) ||
    (isControl && null)
  );
}
